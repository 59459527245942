<template>
    <transition name="fade">
        <section id="reassignment-page">
            <confirmation-panel v-if="reassignment.isComplete"
                :effectiveDate="fullEffectiveDate"
                :newStateAgentCode="targetAgent.stateAgentCode"
                :policyAction="policyAction"
                :previousStateAgentCode="sourceAgent.stateAgentCode"
                :policies="reassignment.data.policies"
                returnTo="Search"
                @closeconfirmation="resetChanges">
            </confirmation-panel>
           <div v-else class="search-and-reassign">
                <h1>
                    Policy Reassignment
                </h1>
                <fieldset class="agents-info">
                    <div class="inputs source">
                        <!--
                        <agent-selector
                            label="Current agent:"
                            :agents="sourceAgentList"
                            :activeOnly="false"
                            :currentAgent="sourceAgent"
                            @setagent="setSourceAgent"
                            :value="sourceAgent.text"
                        >
                        </agent-selector>
                        -->
                        <label>
                            <div>Current agent:</div>
                            <input class="source-agent-input"
                                @change="changeSourceAgent"
                                list="source-agent-list"
                                @keyup="maybeLeaveInput($event)"
                                v-model.lazy="inputSourceAgent"/>
                            <datalist id="source-agent-list" v-if="useBrowserAgentList">
                                <option v-for="agent in agentsList" :key="agent.stateAgentCode">{{agent.stateAgentCode}}</option>
                                <option v-for="agent in agentsList" :key="bareAgentCode(agent.stateAgentCode)">{{bareAgentCode(agent.stateAgentCode)}}</option>
                            </datalist>
                            <div v-show="inputSourceAgent && !sourceAgentText" class="error no-source-agent">Agent {{inputSourceAgent}} not found</div>
                        </label>
                        <span>
                            <br/>
                            <button class="btn std export-to-csv" @click="exportPolicies" :aria-disabled="haveNoAgentPolicies" v-if="sourceAgent.stateAgentCode">
                                Export to CSV
                            </button>
                        </span>
                    </div>
                    <div class="inputs target" :class="{visible: hasSourceAgent && policies.length > 0}">
                        <agent-selector
                            :label="`New agent${targetState ? ' ('+targetState+')' :''}:`"
                            :agents="targetAgentList"
                            :activeOnly="true"
                            :distanceFrom="sourceAgent.location"
                            @setagent="setTargetAgent"
                            :value="sourceAgent.text">
                        </agent-selector>
                        <span>
                            <label>Effective date:</label><br/>
                            <input v-model="effectiveDate"
                                :max="maxDate"
                                :min="minDate"
                                class="effective-date"
                                title="Policy reassignment effective date"
                                type="date">
                        </span>
                    </div>
                </fieldset>
                <div class="table-label-and-radios">
                    <div class="agent-label" v-show="sourceAgent.stateAgentCode">
                        Agent: <strong>{{sourceAgent.text}}</strong>
                    </div>
                    <div role="radiogroup" class="pending-only-toggle" v-if="policies.length > 0 && anyPending">
                        Show
                        <label :class="{selected: !showPendingOnly}">
                            <input type="radio" name="show-all" :checked="!showPendingOnly" :value="false" v-model="showPendingOnly"> Active policies
                        </label>
                        <label :class="{selected: showPendingOnly}">
                            <input type="radio" name="show-all" :checked="showPendingOnly" :value="true" v-model="showPendingOnly"> Pending re-assignment
                        </label>
                    </div>
                </div>
                <div class="policies-list policies-table" v-if="!loading" :class="{pending: showPendingOnly, empty: policies.length===0}">
                    <active-policies-table v-if="!showPendingOnly"
                        @updatepolicy="updatePolicy"
                        :policies="visiblePolicies"
                        :sourceAgent="sourceAgent">
                    </active-policies-table>
                    <pending-policies-table v-if="showPendingOnly"
                        @updatepolicy="updatePolicy"
                        :policies="visiblePolicies"
                        :effectiveDate="fullEffectiveDate"
                        :newAgentCode="targetAgent.stateAgentCode || ''"
                        :sourceAgent="sourceAgent">
                    </pending-policies-table>
                </div>
                <div v-if="policies.length > 0 && !showPendingOnly" class="action-buttons">
                    <button class="btn std reset" title="Reset all pending changes" @click="resetChanges">Reset</button>
                    <button class="btn std primary submit-changes" title="Send reassignments for processing" :aria-disabled="!okToSubmit('isAssigned')" @click.stop.prevent="maybeSubmit">Submit</button>
                </div>
                <div v-if="policies.length > 0 && showPendingOnly" class="action-buttons">
                    <button class="btn std reset" title="Reset all pending changes" @click="resetChanges">Reset</button>
                    <button class="btn std primary submit-changes" title="Cancel pending reassignments" :aria-disabled="!okToAbandon('')" @click.stop.prevent="maybeAbandon">Cancel reassignments</button>
                    <button class="btn std primary submit-changes" title="Send reassignments for processing" :aria-disabled="!okToSubmit('isChecked')" @click.stop.prevent="maybeUpdate">Update reassignments</button>
                </div>
            </div>
            <acknowledgement-modal
                :model="acknowledgement.isOpen"
                :policyAction="policyAction"
                :policies="acknowledgement.policies"
                :sourceAgent="sourceAgent"
                :targetAgent="targetAgent"
                :effectiveDate="fullEffectiveDate"
                @closeacknowledgement="closeAcknowledgement"
            ></acknowledgement-modal>
        </section>
    </transition>
</template>

<script>
import {Net,Urls} from '@/utilities/Net';
import {Utils} from '@/utilities/Utils';
import activePoliciesTable from '@/Components/activePoliciesTable';
import pendingPoliciesTable from '@/Components/pendingPoliciesTable';
import agentSelector from '@/Components/agentSelector';
import acknowledgementModal from '@/Components/acknowledgementModal';
import confirmationPanel from '@/Components/confirmationPanel';

const {stateIds,stateCode,stateName} = Utils,
    cloneObject = (obj) => JSON.parse(JSON.stringify(obj)),
    EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export default {
    name: 'policy-search',
    components: {
        acknowledgementModal,
        activePoliciesTable,
        agentSelector,
        confirmationPanel,
        pendingPoliciesTable,
    },
    data: function() {
        return {
            acknowledgement: {
                isOpen: false,
                policies: [],
            },

            effectiveDate: '',
            loading: false,
            originalPolicies: [],
            policies: [],

            updateCnt: 1,

            policyAction: '',

            reassignment: {
                isComplete: false,
                policies: [],
            },
            showPendingOnly: false,
            inputSourceAgent: '',
            sourceAgent: {},
            sourceAgentText: '',
            targetAgent: {},
            targetAgentText: '',

            useBrowserAgentList: localStorage.useBrowserAgentList === 'true',
        };
    },
    mounted: function() {
        const vue = this;
        vue.refresh();
    },
    computed: {
        anyPending: function() {
            const vue = this;
            return vue.policies.some(policy => policy.pendingReassignPolicyRequestId !== EMPTY_GUID);
        },
        hasSourceAgent: function() {
            const vue = this;
            return Boolean(vue.sourceAgent.stateAgentCode);
        },
        visiblePolicies: function() {
            const vue = this,
                policies = vue.policies;
            return policies.filter(p => vue.showPendingOnly === Boolean(p.pendingReassignPolicyRequestId));
        },
        targetState: function() {
            const vue = this;
            return stateName(stateIds[stateCode((vue.sourceAgent || {}).stateAgentCode)]);
        },
        sourceAgentList: function() {
            const vue = this,
                agentsList = vue.agentsList;

            return agentsList;
        },
        haveNoAgentPolicies: function() {
            const vue = this,
                policies = vue.policies || [];
            return policies.length === 0;
        },
        haveSourceAgent: function() {
            const vue = this,
                haveAgent = agt => Boolean((agt || {}).stateAgentCode);
            return haveAgent(vue.sourceAgent);
        },
        allPoliciesSelected: function() {
            const vue = this;
            return vue.policies.every(policy => policy.isAssigned);
        },
        fullEffectiveDate: function() {
            const vue = this,
                today = new Date(),
                timeNow = today.toTimeString().slice(0,8),
                effDate = vue.effectiveDate || today.toISOString().slice(0,10);

            return (new Date(`${effDate}T${timeNow}`)).toISOString();
        },
        targetAgentList: function() {
            // return agents (except the current agent, unless pending)
            // *in the current agent's state*
            // note that some states (CA, NY, TX) have *multiple* state codes

            const vue = this,
                sourceAgentCode = (vue.sourceAgent || {}).stateAgentCode,
                sourceState = stateCode(sourceAgentCode),
                targetStateCodes = Object.entries(stateIds)
                    .filter(([code,stateCode]) => stateIds[sourceState] === stateCode)
                    .map(entry => entry[0]);

            return vue.sourceAgentList.filter(agt =>
                targetStateCodes.includes(stateCode(agt.stateAgentCode)) &&
                (agt.stateAgentCode !== sourceAgentCode));
        },
    },
    methods: {
        bareAgentCode: function(code) {
            return code.replace(/-/g,'');
        },
        changeSourceAgent: function() {
            const vue = this,
                agent = vue.agentByCode(vue.sourceAgentList,vue.inputSourceAgent.replace(/-/g,'')) || {};
            if (agent.stateAgentCode) {
                vue.inputSourceAgent = agent.stateAgentCode;
            }
            vue.setSourceAgent(agent);
        },
        maybeLeaveInput: function(e) {
            if (e.code === 'Enter') {
                e.target.blur();
            }
        },
        okToAbandon: function() {
            const vue = this,
                updateCnt = vue.updateCnt, // hack to notice change to policies[x]
                changes = vue.policies.filter(p => p.isChecked);
            return Boolean(updateCnt && changes.length > 0);
        },
        okToSubmit: function(prop) {
            const vue = this,
                updateCnt = vue.updateCnt, // hack to notice change to policies[x]
                changes = vue.policies.filter(p => p[prop]);
            return Boolean(updateCnt && changes.length > 0 &&
                vue.targetAgent.stateAgentCode &&
                vue.effectiveDate);
        },
        exportPolicies: function() {
            const vue = this,
                policies = vue.policies || [],
                agent = vue.sourceAgent;

            Utils.exportAgentPolicies(agent,policies);
        },
        selectAgentFilter: function(opts,searchStr) {
            const search = searchStr.replace(/^(..?)(-)(.*)/,(m,p,dash,s) => `${p}${s}`).toLowerCase();

            return search.length > 0 ?
                opts.filter(agt => Boolean(
                    (agt.stateAgentCode || '').replace(/-/g,'').slice(0,search.length).toLowerCase() === search ||
                    (agt.name || '').toLowerCase().match(search))) :
                opts;
        },
        setSourceAgent: function(agent) {
            const vue = this;
            vue.sourceAgent = agent;
            vue.sourceAgentText = (agent || {}).text || '';
            vue.targetAgent = {};
            vue.targetAgentText = '';
        },
        setTargetAgent: function(agent) {
            const vue = this;
            vue.targetAgent = agent;
            vue.targetAgentText = agent.text;
        },
        resetChanges: function() {
            const vue = this;
            vue.policies = cloneObject(vue.originalPolicies);
            vue.policies.forEach(p => p.isChecked = false);
            vue.acknowledgement.isOpen =
                vue.reassignment.isComplete = false;
        },
        submitChanges: function(changes) {
            const vue = this,
                result = vue.policyAction === 'abandon' ?
                    Net.put(Urls.cancelRequests(), {
                        pendingReassignPolicyRequestIds: changes.map(pol => pol.pendingReassignPolicyRequestId)
                    }) :
                    Net.post(Urls.reassignPolicy(),{
                        ReassignPolicyRequests: changes.map(pol => ({
                            confirmationNumber: pol.confirmationNumber,
                            effectiveDate: vue.fullEffectiveDate,
                            id: pol.pendingReassignPolicyRequestId,
                            newAgentOfficeId: vue.targetAgent.id,
                            newStateAgentCode: vue.targetAgent.stateAgentCode,
                            policyNumber: pol.policyNumber,
                            previousAgentOfficeId: vue.sourceAgent.id,
                            previousStateAgentCode: vue.sourceAgent.stateAgentCode,
                            status: 'Pending',
                        }))
                }
            );
            result
                .then(function(data) {
                    const reassignment = vue.reassignment;
                    reassignment.data = data.data[0];
                    reassignment.policies = vue.acknowledgement.policies.slice();
                    vue.acknowledgement.policies = [];
                    reassignment.isComplete = true;
                })
                .catch(err => vue.networkError(err.message));

            return result;
        },
        updatePolicy: function(props) {
            const vue = this,
                policy = vue.policies.find(p => p === props.policy);
            if (policy) {
                policy.isAssigned = props.isAssigned;
                policy.isChecked = props.isChecked;
                vue.updateCnt++;
            }
        },
        maybeSubmit: function() {
            const vue = this;
            if (vue.okToSubmit('isAssigned')) {
                const ack = vue.acknowledgement,
                    changes = vue.policies.filter(p => p.isAssigned);
                ack.policies = changes.map(chg => chg);
                ack.isOpen = true;
                vue.policyAction = 'assign';
            }
        },
        maybeUpdate: function() {
            const vue = this;
            if (vue.okToSubmit('isChecked')) {
                const ack = vue.acknowledgement,
                    changes = vue.policies.filter(p => p.isChecked);
                ack.policies = changes.map(chg => chg);
                ack.isOpen = true;
                vue.policyAction = 'assign';
            }
        },
        maybeAbandon: function() {
            const vue = this;
            if (vue.okToAbandon()) {
                const ack = vue.acknowledgement,
                    changes = vue.policies.filter(p => p.isChecked);
                ack.policies = changes.map(chg => chg);
                ack.isOpen = true;
                vue.policyAction = 'abandon';
            }
        },
        closeAcknowledgement: function(andSubmit) {
            const vue = this,
                ack = vue.acknowledgement;
            ack.isOpen = false;
            if (andSubmit) {
                vue.submitChanges(ack.policies)
                    .finally(function(data) {
                        vue.refresh();
                    });
            }
        },
        refresh: function() {
            const vue = this;

            function refreshPolicies() {
                vue.originalPolicies = [];
                vue.policies = [];
                vue.showPendingOnly = false;
                if (vue.sourceAgent.stateAgentCode) {
                    const result = Net.post(Urls.getPolicies(),{stateAgentCode: vue.sourceAgent.stateAgentCode,...Utils.fullPage});
                    result
                        .then(policies => {
                            const page = policies.data;
                            vue.policies = page.items.map(p => {
                                return {
                                    ...p,
                                    ...{
                                        isAssigned: Boolean(p.effectiveDate && (p.newAgent || {}).stateAgentCode),
                                        effectiveDate: p.effectiveDate || '',
                                        newAgent: p.newAgent || {},
                                    }
                                };
                            });
                            vue.originalPolicies = cloneObject(vue.policies);
                        })
                        .catch(e => {
                            console.error('cannot retrieve policies: ',e);
                            vue.networkError(`cannot retrieve policies: ${e.message}`);
                        });
                    return result;
                } else {
                    return Promise.reject(new Error('no stateAgentCode'));
                }
            }

            vue.loading = true;
            refreshPolicies()
                .then(() => vue.loading = false)
                .catch(() => false);
        },
    },
    watch: {
        sourceAgent: function() {
            const vue = this;
            vue.refresh();
        },
    }
}
</script>

<style lang="scss">
@import "../styles/sfadmin";

#reassignment-page {
    padding-bottom: 1em;
    ::placeholder {
        font-style: italic;
        opacity: 0.7;
    }
    .confirmation,
    h1 {
        margin: 0 auto;
        text-align: center;
    }
    .action-buttons,
    .agents-info {
        border: none;
        margin: 1em;
        width: 95%;
    }
    .source-agent-input {
        border: 1px solid rgba(60,60,60,0.26);
        border-radius: 4px;
        font-size: 1em;
        height: 2em;
        outline: none;
        padding-left: 0.5em;
        width: 19.2em;
    }
    .no-source-agent {
        color: var(--sf_red);
    }
    .pending-only-toggle {
        text-align: center;
        label.selected {
            color: var(--sf_red);
            font-weight: bold;
        }
    }
    .inputs {
        display: flex;
        gap: 2em;
        margin: 0.5em 0 1.2em;
    }
    .inputs.target {
        visibility: hidden;
        &.visible {
            visibility: visible;
        }
    }
    .inputs .effective-date {
        width: 10em;
    }
    .table-label-and-radios {
        display: flex;
        gap: 2em;
        justify-content: left;
        margin-left: 2em;
    }
    .policies-table:not(.pending) {
        .col-pending-agt,
        .col-pending-eff-dt {
            display: none;
        }
    }
    .action-buttons {
        display: flex;
        justify-content: space-around;
        margin-top: 1em;
        padding-bottom: 0.6em;
    }
}
</style>
