<template>
    <transition name="fade">
        <table>
            <thead>
                <tr>
                    <th class="col-select">
                        <label class="select-all">
                            <input type="checkbox"
                                :disabled="!haveSourceAgent"
                                :checked="allPoliciesSelected()"
                                @change="selectAll($event,allPoliciesSelected())"
                                aria-label="Check or clear all"
                                title="Select/Unselect all"
                                id="select-all-policies"
                                v-model="selectAllPolicies">
                        </label>
                    </th>
                    <th class="col-name sortable" title="Click to sort" :class="{sorted: activeSort==='name', 'sort-reverse': sortOrder < 0}" @click="sortByName">Name</th>
                    <th class="col-policy sortable" title="Click to sort" :class="{sorted: activeSort==='policy', 'sort-reverse': sortOrder < 0}" @click="sortByPolicy">Policy</th>
                    <th class="col-date sortable" title="Click to sort" :class="{sorted: activeSort==='date', 'sort-reverse': sortOrder < 0}" @click="sortByDate">Date</th>
                    <th class="col-postal">Postal</th>
                    <th class="col-state">State</th>
                    <th class="col-pets">Pets</th>
                </tr>
            </thead>
            <tr v-if="rows.length === 0 && sourceAgent.name">
                <td colspan="7" class="no-data">
                    No active policies found for {{sourceAgent.name}}
                </td>
            </tr>
            <tr v-for="(row, index) in rows" :key="rowKey(index)" class="policy-row" :class="{highlight: row.isAssigned}" @click="toggleSelection($event,row)">

                <td class="col-select">
                    <input type="checkbox" :checked="row.isAssigned" v-model="row.isAssigned">
                </td>
                <td class="col-name">{{fullName(row)}}</td>
                <td class="col-policy">{{row.policyNumber}}</td>
                <td class="col-date">{{row.policyDate | asDate}}</td>
                <td class="col-postal">{{row.postalCode}}</td>
                <td class="col-state">{{row.stateCode}}</td>
                <td class="col-pets">{{petNames(row)}}</td>
            </tr>
        </table>
    </transition>
</template>

<script>

export default {
    name: 'active-policies-table',
    data: function() {
        return {
            activeSort: '',
            allCheckbox: null,
            rows: [],
            sortOrder: 1,
            selectAllPolicies: false,
        }
    },
    mounted: function() {
        const vue = this;
        vue.rows = vue.policies.slice();
        vue.allCheckbox = document.querySelector('#select-all-policies');
        vue.allCheckbox.indeterminate = vue.isIndeterminate();
    },
    props: {
        policies: {
            type: Array
        },
        sourceAgent: {
            type: Object
        }
    },
    computed: {
        haveSourceAgent: function() {
            const vue = this,
                haveAgent = agt => Boolean((agt || {}).stateAgentCode);
            return haveAgent(vue.sourceAgent);
        },
    },
    methods: {
        allPoliciesUnselected: function() {
            const vue = this;
            return vue.rows.every(policy => !policy.isAssigned);
        },
        allPoliciesSelected: function() {
            const vue = this;
            return vue.rows.every(policy => policy.isAssigned);
        },
        isIndeterminate: function() {
            const vue = this;
            return !vue.allPoliciesSelected() && !vue.allPoliciesUnselected();
        },
        fullName: (policy) => `${policy.firstName || ''} ${policy.lastName || ''}`,
        petNames: (policy) => policy.insuredPets.map(pet => pet.name || '').join(', '),
        rowKey: function(index) {
            const vue = this,
                  policyNumber = vue.rows[index].policyNumber;
            return `R-${policyNumber}`;
        },
        newOrder: function(active) {
            const vue = this;
            return vue.activeSort === active ? -1 * vue.sortOrder : 1;
        },
        resetSort: function(name,order,rows)  {
            const vue = this;
            vue.activeSort = name;
            vue.sortOrder = order;
            vue.rows = rows.slice();
        },
        sortByName: function() {
            const vue = this,
                nm = (name) => (name || '').toLowerCase(),
                full = rec => nm(rec.firstName) + ' ' + nm(rec.lastName),
                order = vue.newOrder('name');
            let rows = vue.rows;

            if (vue.activeSort === 'name') {
                rows = rows.reverse();
            } else {
                rows.sort(function(arec,brec) {
                    const a = full(arec),
                        b = full(brec);
                    return a === b ? 0 : a < b ? -order : order;
                });
            }
            vue.resetSort('name',order,rows);
        },
        sortByDate: function() {
            const vue = this,
                order = vue.newOrder('date');
            let rows = vue.rows;

            if (vue.activeSort === 'date') {
                rows = rows.reverse();
            } else {
                rows.sort(function(a,b) {
                    return !a.policyDate ? -1 :
                        !b.policyDate ? 1 :
                        a.policyDate === b.policyDate ? 0 : a.policyDate < b.policyDate ? -order : order;
                });
            }
            vue.resetSort('date',order,rows);
        },
        sortByPolicy: function() {
            const vue = this,
                order = vue.newOrder('policy');
            let rows = vue.rows;

            if (vue.activeSort === 'policy') {
                rows = rows.reverse();
            } else {
                rows.sort(function(a,b) {
                    return a.policyNumber === b.policyNumber ? 0 : a.policyNumber < b.policyNumber ? -order : order;
                });
            }
            vue.resetSort('policy',order,rows);
        },
        toggleSelection: function(ev,row) {
            const vue = this,
                assigned = !row.isAssigned;

            row.isAssigned = assigned;
            vue.selectAllPolicies = vue.policies.every(policy => policy.isAssigned);
            vue.allCheckbox.indeterminate = vue.isIndeterminate();
            vue.$emit('updatepolicy',{policy: row, isAssigned: assigned});
            vue.$forceUpdate();
        },
        selectAll: function(ev,isOn) {
            const vue = this;

            vue.rows.forEach(row => {
                row.isAssigned = !isOn;
                vue.$emit('updatepolicy',{policy: row, isAssigned: !isOn});
            });
            vue.allCheckbox.indeterminate = vue.isIndeterminate();
            vue.$forceUpdate();
        },
    },
    watch: {
        policies: function() {
            const vue = this;
            vue.selectAllPolicies = false;
            vue.rows = vue.policies.slice();
            vue.allCheckbox.indeterminate = vue.isIndeterminate();
        },
    },

}
</script>
